import Swal from 'sweetalert2'
import Vue from 'vue'
import axios from './axios'

const disparaErro = _err => {
  if (_err.response.status === 422) {
    let html = ''
    if (_err.response.data.message) {
      html = _err.response.data.message
    } else {
      html = _err.response.data.reduce((acc, curr) => acc + curr + '<br>', '')
    }
    Swal.fire({
      title: 'Ops!',
      html: html,
      icon: 'error',
    })
  }
}

const requestApi = (
  method,
  url,
  params = {},
  data = {},
  tratarErro = true,
  headers = {},
) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      params,
      data,
      headers,
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(_err => {
        if (tratarErro) {
          disparaErro(_err)
        }
        reject(_err.response.data)
      })
  })
}

const api = {
  auth: {
    createPasswordByToken: (token, password, confirmPassword) =>
      requestApi(
        'post',
        '/auth/createPasswordByToken',
        {},
        { token, password, confirmPassword },
      ),
    login: (email, password) =>
      requestApi('post', '/auth/login/web', {}, { email, password }),
    verifyEmailByToken: token =>
      requestApi('post', '/auth/verifyEmail', {}, { token }),
    updatePassword: (email, oldPassword, password, confirmPassword) => {
      return requestApi(
        'post',
        '/auth/update-password',
        {},
        {
          email,
          oldPassword,
          password,
          password_confirmation: confirmPassword,
        },
      )
    },
  },
  get: {
    agroindustria: CodAgroindustria =>
      requestApi('get', `/agroindustria/${CodAgroindustria}`),
    agroindustrias: () => requestApi('get', '/agroindustria'),
    cidades: CodEstado => requestApi('get', '/cidade', { CodEstado }),
    consultores: (active = null) =>
      requestApi('get', '/consultores', { active }),
    getConsultores: active =>
      requestApi('get', '/consultores/getConsultores', { active }),
    entregas: () => requestApi('get', '/entrega'),
    entrega: id => requestApi('get', `/entrega/${id}`),
    consultoresList: () => requestApi('get', '/consultores/list'),
    consultor: id => requestApi('get', `/consultores/find/${id}`),
    dashboard: () => requestApi('get', '/dashboard'),
    estados: () => requestApi('get', '/estado'),
    fazendas: () => requestApi('get', '/fazendas'),
    gestor: CodGestor => requestApi('get', `/gestores/${CodGestor}`),
    endereco: CodPessoa => requestApi('get', `/endereco/${CodPessoa}`),
    configUser: CodUsuario => requestApi('get', `/configUser/${CodUsuario}`),
    gestores: () => requestApi('get', '/gestores'),
    synclog: () => requestApi('get', '/syncLog/logs'),
    synclogdetails: id => requestApi('get', `/syncLog/log/${id}`),
    relatorio: CodRelatorio => requestApi('get', `/relatorios/${CodRelatorio}`),
    relatoriovalidacao: CodRelatorio =>
      requestApi('get', `/validation/${CodRelatorio}`),
    relatorioccs: (CodRelatorio, DraftReport) =>
      requestApi('get', `/report/${CodRelatorio}`, { DraftReport }),
    relatorioStructure: (CodRelatorio, DraftReport) =>
      requestApi('get', `/report/${CodRelatorio}`, { DraftReport }),

    deliveryReport: (CodRelatorio, DraftReport) =>
      requestApi('get', `/deliveryreport/${CodRelatorio}`, { DraftReport }),

    getProdutores: ({ id }) =>
      requestApi('get', `/consultores/getProdutores`, { id }),
    //relatorios: (DataInicio, DataFim) =>
    //requestApi('get', '/relatorios', { DataInicio, DataFim }),
    relatorios: (
      DataInicio,
      DataFim,
      ProjectId,
      ReportTypeId,
      DraftReport = false,
    ) =>
      requestApi('get', '/report', {
        DataInicio,
        DataFim,
        ProjectId,
        ReportTypeId,
        DraftReport,
      }),
    reportDelivery: (DataInicio, DataFim, project, DraftReport = false) =>
      requestApi('get', '/deliveryreport/interval', {
        DataInicio,
        DataFim,
        project,
        DraftReport,
      }),
    relatoriosMap: (DataInicio, DataFim, ProjectId, UserId, agroindustria) =>
      requestApi('get', '/report/map', {
        DataInicio,
        DataFim,
        ProjectId,
        UserId,
        agroindustria,
      }),
    fazendasMap: (CodAgroindustria, CodConsultorTecnico) =>
      requestApi('get', '/fazendas/map', {
        CodAgroindustria,
        CodConsultorTecnico,
      }),
    projects: () => requestApi('get', '/projects'),
    projectsstructure: () => requestApi('get', '/projectstructure'),
    projectstructure: id =>
      requestApi('get', `/projectstructure/project/${id}`),
    produtores: () => requestApi('get', '/produtores'),
    produtor: id => requestApi('get', `/produtores/${id}`),
    fazenda: CodFazenda => requestApi('get', `/fazendas/find/${CodFazenda}`),
    centrals: () => requestApi('get', '/central'),
    nota: id => requestApi('get', `/financeiro/find/${id}`),
    notas: () => requestApi('get', '/financeiro'),
    central: id => requestApi('get', `/central/${id}`),
    prestador: id => requestApi('get', `/prestador/${id}`),
    prestadores: () => requestApi('get', '/prestador'),
    getAssociation: id => requestApi('get', `/central/association/all/${id}`),
    getDashboardGeneralVisit: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/generalVisit', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        reportId,
        activeProducers,
      }),
    getDashboardCCSVisit: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/ccsVisit', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        reportId,
        activeProducers,
      }),
    getDashboardCPP: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      slug,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/cpp', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        slug,
        activeProducers,
      }),
    getDashboardEvoluiLeite: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/evoluiLeite', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        reportId,
        activeProducers,
      }),
    getDashboardEducampo: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/generalVisit', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        reportId,
        activeProducers,
      }),
    getDashboardLactalis: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      reportId,
      activeProducers = 0,
    ) =>
      requestApi('get', '/dashboardreport/lactalis', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        reportId,
        activeProducers,
      }),
    getDashboardSolidos: (
      DataInicio,
      DataFim,
      producer,
      farm,
      agroindustry,
      consultant,
      slug,
      activeProducers = 0,
      reportTypeId = 13,
    ) =>
      requestApi('get', '/dashboardreport/solidos', {
        DataInicio,
        DataFim,
        producer,
        farm,
        agroindustry,
        consultant,
        slug,
        activeProducers,
        reportTypeId,
      }),
    getDashboardFinanceiro: (DataInicio, DataFim) =>
      requestApi('get', '/financeiro/dashboard', {
        DataInicio,
        DataFim,
      }),
    listReports: ({ projectId, id }) =>
      requestApi('get', `/permissions/listReports`, { id: id }, {}, true, {
        projectId,
      }),

    listReportsType: () => requestApi('get', `/reportType`, {}, {}, true, {}),

    consultantDashboard: ({ id, DataInicio, DataFim, projectId }) =>
      requestApi('get', `/consultores/dashboard`, {
        id,
        DataInicio,
        DataFim,
        projectId,
      }),

    reportData: ({ DataInicio, DataFim, id }) =>
      requestApi('get', `/consultores/finacial/reports`, {
        DataInicio,
        DataFim,
        id,
      }),

    consultorFinacial: () => requestApi('get', `/consultores/finacial`, {}),
    consultorFindFinacial: id =>
      requestApi('get', `/consultores/finacial/find/${id}`, {}),
    allFinacial: () => requestApi('get', `/finacial/all`, {}),
    numberOfVisits: (farmId, reportTypeId) =>
      requestApi('get', `/fazendas/numberOfVisits`, { farmId, reportTypeId }),

    schedules: ({ id, startTime, endTime }) =>
      requestApi('get', '/schedule', { id, startTime, endTime }),
    searchSchedules: id => requestApi('get', `/schedule/search/${id}`),
    projectsPlanning: ({ agroindustry }) =>
      requestApi('get', '/projectPlanning', { agroindustry }),
    projectFarm: id => requestApi('get', `/projectPlanning/farmProject/${id}`),
    projectPlanning: id => requestApi('get', `/projectPlanning/${id}`),
    dashboardVisit: id =>
      requestApi('get', `/projectPlanning/dashboardVisit/${id}`),
    dashboardControl: id =>
      requestApi('get', `/projectPlanning/dashboardControl/${id}`),
    getReportFields: (id, fields) =>
      requestApi('get', `/report/fields/${id}`, { fields }, {}),

    getFarmDistribution: consultores => {
      return requestApi('get', `/farmDistribution`, { consultores })
    },
  },
  delete: {
    agroindustria: CodAgroindustria =>
      requestApi('delete', '/agroindustria/deletar', { CodAgroindustria }),
    gestor: CodGestor => requestApi('put', `/gestores/delete/${CodGestor}`),
    prestador: id => requestApi('put', `/prestador/delete/${id}`),
    nota: id => requestApi('put', `/financeiro/delete/${id}`),
    relatoriovalidacao: id => requestApi('put', `/validation/delete/${id}`),
    relatorio: CodRelatorio =>
      requestApi('delete', `/relatorio/deletar`, { CodRelatorio }),
    relatorioccs: (CodRelatorio, DraftReport = 0) =>
      requestApi('delete', `/report/deletar`, { CodRelatorio, DraftReport }),
    deleteLogo: id => requestApi('delete', `/agroindustria/deleteLogo/${id}`),
    central: id => requestApi('delete', `/central/${id}`),
    consultorFinacial: id =>
      requestApi('delete', `/consultores/finacial/${id}`),
    delivery: id => requestApi('delete', `/deliveryreport/${id}`),
    deliveryReport: id => requestApi('delete', `/entrega/${id}`),
    schedule: id => requestApi('delete', `/schedule/${id}`),
    inactivateFarmProject: id =>
      requestApi('delete', `/projectPlanning/farmProject/inactivate/${id}`),
    projectPlanning: id => requestApi('delete', `/projectPlanning/${id}`),
    financialRelease: id => {
      return requestApi('delete', `/financialRelease/${id}`)
    },
  },
  save: {
    agroindustria: form => requestApi('post', '/agroindustria/criar', {}, form),
    fazendaAgroindustria: form =>
      requestApi('post', '/fazendas/agroindustria', {}, form),
    gestor: form => requestApi('post', '/gestores/save', {}, form),
    recuperarsenha: email =>
      requestApi('post', '/reset-password/reset', {}, { email }, false),
    fazendas: form => requestApi('post', '/fazendas', {}, form),
    produtor: form => requestApi('post', '/produtores', {}, form),
    consultor: form => requestApi('post', '/consultores', {}, form),
    entrega: form => requestApi('post', '/entrega/create', {}, form),
    central: form => requestApi('post', '/central', {}, form),
    relatoriovalidacao: form => requestApi('post', '/validation', {}, form),
    prestador: form => requestApi('post', '/prestador', {}, form),
    nota: form =>
      requestApi(
        'post',
        '/financeiro',
        { headers: { 'Content-Type': 'multipart/form-data' } },
        form,
      ),

    createConsultorFinacial: form =>
      requestApi('post', '/consultores/finacial', {}, form),

    createSchedule: form => {
      return requestApi('post', `/schedule`, {}, form)
    },
    createProjectPlanning: form => {
      return requestApi('post', `/projectPlanning`, {}, form)
    },
    financialRelease: form => {
      return requestApi('post', `/financialRelease`, {}, form)
    },
  },
  update: {
    agroindustria: (CodAgroindustria, form) =>
      requestApi('put', `/agroindustria/${CodAgroindustria}`, {}, form),
    updateNumeroVisitaRelatorios: (CodFazenda, form) =>
      requestApi(
        'put',
        `/fazendas/${CodFazenda}/relatorios/update-numero-visita`,
        {},
        form,
      ),
    fazendaAgroindustria: (CodFazendaAgroindustria, form) =>
      requestApi(
        'put',
        `/fazendas/agroindustria/${CodFazendaAgroindustria}`,
        {},
        form,
      ),
    perfil: (CodPessoa, form) =>
      requestApi('put', `/user/update/${CodPessoa}`, {}, form),
    gestor: (CodGestor, form) =>
      requestApi('put', `/gestores/update/${CodGestor}`, {}, form),
    fazenda: (CodFazenda, form) =>
      requestApi('put', `/fazendas/update/${CodFazenda}`, {}, form),
    fazendaState: (id, form) =>
      requestApi('put', `/fazendas/state/${id}`, {}, form),
    produtor: (id, form) => requestApi('put', `/produtores/${id}`, {}, form),
    consultor: (id, form) =>
      requestApi('put', `/consultores/update/${id}`, {}, form),
    consultorState: (id, form) =>
      requestApi('put', `/consultores/state/${id}`, {}, form),
    produtorState: (id, form) =>
      requestApi('put', `/produtores/state/${id}`, {}, form),
    central: (id, form) => requestApi('put', `/central/${id}`, {}, form),
    prestador: (id, form) => requestApi('put', `/prestador/${id}`, {}, form),
    entrega: (id, form) => requestApi('put', `/entrega/${id}`, {}, form),
    nota: (id, form) =>
      requestApi(
        'post',
        `/financeiro/${id}`,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        form,
      ),
    configUser: (CodUsuario, form) =>
      requestApi('put', `/configUser/${CodUsuario}`, {}, form),
    association: (id, form) =>
      requestApi('put', `/central/association/${id}`, {}, form),
    permission: ({ slug, id, permission }) =>
      requestApi(
        'put',
        `/permissions/update`,
        {},
        { id, permissions: permission },
        true,
        {
          slug,
        },
      ),

    updateConsultorFinacial: (id, form) =>
      requestApi('put', `/consultores/finacial/${id}`, {}, form),

    financialRelease: (id, form) => {
      return requestApi('put', `/financialRelease/${id}`, {}, form)
    },
    updateConsultantFarm: (consultor, fazenda) => {
      return requestApi(
        'put',
        `/farmDistribution`,
        {},
        {
          consultor,
          fazenda,
        },
      )
    },
  },

  patch: {
    schedule(id, form) {
      return requestApi('patch', `/schedule/${id}`, {}, form)
    },
    farmProject(id, form) {
      return requestApi('patch', `/projectPlanning/farmProject/${id}`, {}, form)
    },

    projectPlanning: (id, form) =>
      requestApi('patch', `/projectPlanning/${id}`, {}, form),

    patchReport: (id, form) =>
      requestApi('patch', `/report/update/${id}`, {}, form),

    filters: (params, farms) => {
      return requestApi('patch', '/filters', {}, { params, farms })
    },
    patchReportFields: (id, fields) =>
      requestApi('patch', `/report/fields/${id}`, {}, { fields }),
  },
}

Vue.prototype.api = api

export default api
