const permissions = [
  {
    role: 'consultor',
    access: false,
    redirect: 'consultor',
  },
  {
    role: 'central',
    access: false,
    redirect: 'central',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

export default {
  /* ACESSO ADMINISTRADOR */
  name: 'admin',
  path: '/admin',
  redirect: '/admin/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/Dashboard'),
      meta: { title: 'Dashboard', requiresAuth: true, permissions },
    },
    {
      path: 'dashboardConsultor',
      component: () => import('@/views/consultores/dashboard/index'),
      meta: { title: 'Consultores', requiresAuth: true, permissions },
    },
    {
      path: 'agroindustrias',
      component: () => import('@/views/agroindustrias/AgroindustriaList'),
      meta: { title: 'Agroindústrias', requiresAuth: true, permissions },
    },
    {
      path: 'agroindustrias/form',
      component: () => import('@/views/agroindustrias/AgroindustriaForm'),
      meta: {
        title: 'Cadastro de agroindústria',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'fazendas',
      component: () => import('@/views/fazendas/FazendaList'),
      meta: {
        title: 'Fazendas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'fazendas/form',
      component: () => import('@/views/fazendas/FazendaForm'),
      meta: {
        title: 'Cadastro de Fazendas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'consultores',
      component: () => import('@/views/consultores/ConsultorList'),
      meta: { title: 'Consultores', requiresAuth: true, permissions },
    },
    {
      path: 'gestores',
      component: () => import('@/views/gestores/GestorList'),
      meta: { title: 'Gestores', requiresAuth: true, permissions },
    },
    {
      path: 'gestores/form',
      component: () => import('@/views/gestores/GestorForm'),
      meta: { title: 'Cadastro de Gestor', requiresAuth: true, permissions },
    },
    {
      path: 'produtores',
      component: () => import('@/views/produtores/ProdutoresList'),
      meta: {
        title: 'Produtores',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'produtores/form',
      component: () => import('@/views/produtores/ProdutoresForm'),
      meta: {
        title: 'Cadastro de Produtor',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'central',
      component: () => import('@/views/central/CentralList'),
      meta: {
        title: 'Supervisores',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'financeiro',
      component: () => import('@/views/financeiro/FinanceiroList'),
      meta: {
        title: 'Cronograma Financeiro',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'financeiro/create',
      component: () => import('@/views/financeiro/consultor/index'),
      meta: { title: 'Lançamento Financeiro', requiresAuth: true, permissions },
    },
    {
      path: 'financeiros',
      component: () => import('@/views/financeiro/gestor/FinanceiroList'),
      meta: {
        title: 'Cronograma Financeiro',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'odometer',
      component: () => import('@/views/financeiro/odometer/OdometerList'),
      meta: {
        title: 'Lançamento de percurso',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'consultancy',
      component: () => import('@/views/consultoria/ConsultoriaList'),
      meta: {
        title: 'Consultoria',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'consultancy/form',
      component: () => import('@/views/consultoria/ConsultoriaForm'),
      meta: {
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'prestador',
      component: () => import('@/views/prestadores/PrestadorList'),
      meta: {
        title: 'Prestadores de serviço',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'prestador/form',
      component: () => import('@/views/prestadores/PrestadorForm'),
      meta: {
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'consultor/form',
      component: () => import('@/views/consultores/ConsultorForm'),
      meta: {
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'central/form',
      component: () => import('@/views/central/CentralForm'),
      meta: {
        title: 'Cadastro de supervisor',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'central/association',
      component: () => import('@/views/central/CentralAssociation'),
      meta: {
        title: 'Associação do supervisor',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'schedules',
      component: () => import('@/views/visitas/index'),
      meta: { title: 'Visitas', requiresAuth: true, permissions },
    },
    {
      path: 'projects',
      component: () => import('@/views/project/index'),
      meta: { title: 'Projetos', requiresAuth: true, permissions },
    },
    {
      path: 'project/form',
      component: () => import('@/views/project/form'),
      meta: { title: 'Projetos', requiresAuth: true, permissions },
      props: true,
    },
    {
      path: 'projectFarm/form',
      component: () => import('@/views/project/fazendaProject/form'),
      meta: { title: 'Fazenda', requiresAuth: true, permissions },
      props: true,
    },
    {
      path: 'projectFarm/dashboard',
      component: () => import('@/views/project/dashboard/index'),
      meta: { title: 'Fazenda', requiresAuth: true, permissions },
      props: true,
    },
    {
      path: 'relatorios/geral',
      component: () => import('@/views/relatorios/geral/RelatorioList'),
      meta: {
        title: 'Visita Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/visitas',
      component: () => import('@/views/relatorios/visitas/index.vue'),
      meta: {
        title: 'Visitas por Consultor',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/sinc',
      component: () => import('@/views/relatorios/sinc/RelatorioListSinc'),
      meta: {
        title: 'Sincronização de Visitas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/deslocamento',
      component: () => import('@/views/relatorios/deslocamento/RelatorioList'),
      meta: {
        title: 'Deslocamento de Consultores',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/localizacao',
      component: () => import('@/views/relatorios/localizacao/RelatorioList'),
      meta: {
        title: 'Localização das Propriedades',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/entrega',
      component: () =>
        import('@/views/relatorios/entrega/RelatorioListEntrega'),
      meta: {
        title: 'Entrega de Material',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/entrega/form',
      component: () => import('@/views/relatorios/entrega/RelatorioForm'),
      meta: {
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/geral/view',
      component: () => import('@/views/relatorios/geral/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório de Visita Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ccs',
      component: () => import('@/views/relatorios/ccs/RelatorioListCCS'),
      meta: {
        title: 'Visita CCS',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ccs/view',
      component: () => import('@/views/relatorios/ccs/show/RelatorioViewCCS'),
      meta: {
        title: 'Visualização de relatório de Visita CCS',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/cpp',
      component: () => import('@/views/relatorios/cpp/RelatorioListCPP'),
      meta: {
        title: 'Visita CPP',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/cpp/view',
      component: () => import('@/views/relatorios/cpp/show/RelatorioViewCPP'),
      meta: {
        title: 'Visualização de relatório de Visita CPP',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/evoluileite',
      component: () =>
        import('@/views/relatorios/evoluiLeite/RelatorioListEvolui'),
      meta: {
        title: 'Visita Evolui Leite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/evoluileite/view',
      component: () =>
        import('@/views/relatorios/evoluiLeite/show/RelatorioViewEvolui'),
      meta: {
        title: 'Visualização de Visita Evolui Leite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/boaspraticas',
      component: () =>
        import('@/views/relatorios/boasPraticas/RelatorioListBP'),
      meta: {
        title: 'Visita de Boas Práticas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/boaspraticas/view',
      component: () =>
        import('@/views/relatorios/boasPraticas/show/RelatorioViewBP'),
      meta: {
        title: 'Visualização de relatório Boas Práticas Agropecuárias',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/atb',
      component: () => import('@/views/relatorios/atb/RelatorioListATB'),
      meta: {
        title: 'Visita de Resíduos de ATB',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/atb/view',
      component: () => import('@/views/relatorios/atb/show/RelatorioViewATB'),
      meta: {
        title: 'Visualização de relatório de resíduos de ATB',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/fraudes',
      component: () => import('@/views/relatorios/fraudes/RelatorioListFraude'),
      meta: {
        title: 'Visita de Fraudes',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/fraudes/view',
      component: () =>
        import('@/views/relatorios/fraudes/show/RelatorioViewFraude'),
      meta: {
        title: 'Visualização de relatório de fraudes',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/bonolat',
      component: () =>
        import('@/views/relatorios/bonolat/RelatorioListBonolat'),
      meta: {
        title: 'Visita Bonolat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/bonolat/view',
      component: () =>
        import('@/views/relatorios/bonolat/show/RelatorioViewBonolat'),
      meta: {
        title: 'Visualização de relatório de visita Bonolat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/educampo',
      component: () => import('@/views/relatorios/educampo/RelatorioList'),
      meta: {
        title: 'Visita Educampo',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/educampo/view',
      component: () => import('@/views/relatorios/educampo/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório educampo',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactalis',
      component: () => import('@/views/relatorios/lactalis/RelatorioList'),
      meta: {
        title: 'Visita Lactalis',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactalis/view',
      component: () => import('@/views/relatorios/lactalis/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório Lactalis',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/embrioes',
      component: () => import('@/views/relatorios/embrioes/RelatorioList'),
      meta: {
        title: 'Visita relatório de embriões',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/embrioes/view',
      component: () => import('@/views/relatorios/embrioes/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório de embriões',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactaleite',
      component: () => import('@/views/relatorios/lactaleite/RelatorioList'),
      meta: {
        title: 'Visita relatório lactaleite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactaleite/view',
      component: () =>
        import('@/views/relatorios/lactaleite/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório lactaleite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/solidos',
      component: () => import('@/views/relatorios/solidos/RelatorioList'),
      meta: {
        title: 'Visita relatório Sólidos',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/solidos/view',
      component: () => import('@/views/relatorios/solidos/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório Sólidos',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ordenha',
      component: () => import('@/views/relatorios/ordenha/OrdenhaList'),
      meta: {
        title: 'Diagnóstico Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ordenha/view',
      component: () => import('@/views/relatorios/ordenha/show/RelatorioView'),
      meta: {
        title: 'Visualização do Diagnóstico Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/promilat',
      component: () => import('@/views/relatorios/promilat/RelatorioList'),
      meta: {
        title: 'Visita relatório Promilat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/promilat/view',
      component: () => import('@/views/relatorios/promilat/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório Promilat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/farmDistribution',
      component: () => import('@/views/relatorios/farmDistribution/index.vue'),
      meta: {
        title: 'Distribuição de fazendas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/entrega',
      component: () =>
        import('@/views/relatorios/entregaReport/DateInputs.vue'),
      meta: {
        title: 'Relatório de Entrega',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/entregas',
      component: () => import('@/views/relatorios/entregaReport/RelatorioList'),
      meta: {
        title: 'Relatório de Entregas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'trocar-senha',
      component: () => import('@/views/ChangePassword'),
      meta: {
        title: 'Atualizar senha',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/configuracoes',
      component: () => import('@/views/relatorios/create'),
      meta: { title: 'Configurar relatórios', requiresAuth: true, permissions },
    },
  ],
}
