const apiUri            = 'https://testeapi.plataformassist.com.br/api' // ambiente de testes
const relUri            = 'www.plataformassist.com.br'
const imgFileLogin      = 'vigor-assist.png'
const imgFileMenu       = 'vigor-assist-branca.png'
const availableProjects = ['milk-plan-report', 'milk-plan-forms']
const ProjectID         = '1' 
const slug              = 'milk-plan-report'

export const ENV_APP = {
    apiUri,
    relUri,
    imgFileLogin,
    imgFileMenu,
    availableProjects,
    ProjectID,
    slug, 	
}
