import Toast from '../../plugins/toast'
import reportTypeService from '../../services/api/reportType'

export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_REPORT_TYPE = 'LOAD_REPORT_TYPE'
export const SET_STATE = 'SET_STATE'

const state = {
  ready: false,
  reportTypes: [],
}

const mutations = {
  SET_READY(state, payload) {
    state.ready = payload
  },
  SET_STATE(state, payload) {
    state = { ...state, ...payload }
  },
  SET_REPORT_TYPES(state, payload) {
    state.reportTypes = payload
  },
}

const actions = {
  async [LOAD_REPORT_TYPE]({ commit }) {
    commit('SET_READY', true)
    reportTypeService
      .list()
      .then(reportTypes => {
        commit('SET_REPORT_TYPES', reportTypes)
      })
      .catch(() => {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os tipos de relatórios',
        })
      })
      .finally(() => {
        commit('SET_READY', false)
      })
  },

  async [BOOTSTRAP]({ dispatch }) {
    dispatch(LOAD_REPORT_TYPE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
